.login-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: linear-gradient(#004f4a 10%, #0a8a83);

  .login-title {
    font-size: 30px;
    line-height: 1.2;
    text-align: center;
  }

  .login-wrapper {
    background: #fff;
    width: 390px;
    border-radius: 10px;
    padding: 30px 40px 30px;
  }

  .btn-login {
    background: #0a8a83;
    color: white;
    &:hover {
      background-color: darken($color: #0a8a83, $amount: 10);
    }
  }

  @media (max-width: 480px) {
    .login-wrapper {
      width: 390px;
      padding: 20px 15px;
      margin: 0 10px;
    }
  }
}
