@for $i from 5 to 40 {
  .font-#{$i} {
    font-size: #{$i}px !important;
  }
}

.font-bold {
  font-weight: 700;
}

.opensans {
  font-family: 'Open Sans', sans-serif;
}

.roboto {
  font-family: 'Roboto condensed', sans-serif;
}

[data-toggle='tooltip']:hover,
.pointer:hover {
  cursor: pointer;
}

#map {
  min-height: 100vh;
}

.btn-reset {
  background-color: #fff;
  border-color: #00000033;
  padding: 3px 8px;
}
.select2 {
  width: 100% !important;
}
@media (max-width: 720px) {
  #map {
    min-height: 70vh;
  }
  .card-radio {
    .card-body {
      flex-direction: column;
      text-align: center;
    }
  }
  .map-sidebar {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    max-height: initial;
    overflow-y: initial;
  }
}

.map-sidebar {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 1px solid #ccc;

  max-height: 100vh;
  overflow-y: auto;

  input[type='radio'] {
    display: none;
  }

  .card-radio {
    color: $secondary3;
    &:hover {
      transition: 0.3s all ease-in-out;
      cursor: pointer;
      border-color: $primary1;
      color: $primary1;
    }
    &.active {
      background-color: $primary1;
      color: $white;
      .fa-check-circle {
        display: initial;
      }
      .fa-circle {
        display: none;
      }
    }
    .card-body {
      display: flex;
      align-items: center;
    }
    .icon {
      font-size: 1.5em;
      width: 20%;
    }

    .fa-check-circle {
      display: none;
    }
    .fa-circle {
      display: initial;
    }
  }

  .table {
    th {
      font-family: 'Roboto Condensed';
    }
  }

  .table {
    td,
    th {
      vertical-align: middle;
    }
    .icons {
      text-align: center;
      font-size: 2.3em;
      .icon-tooltip {
        display: inline-block;
        padding: 0 2px;
        margin-bottom: 2px;
      }
    }
  }
}

.info-control {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.info-control h4 {
  margin: 0 0 5px;
  color: #777;
}

.info-container {
  > section {
    background-color: #e9ecef;
    padding: 1em;
    border-radius: 5px;
  }

  .gradient {
    font-family: 'Roboto Condensed';
    color: white;
    text-align: center;
    text-shadow: 0 0 3px #000;
    background-color: red; /* For browsers that do not support gradients */
    background-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
  }

  .show-info {
    animation: shadowFrame 2s infinite;
    text-shadow: 0 0 10px red;
    &:hover {
      cursor: pointer;
    }
  }

  .btn-async-modal {
    &:hover {
      cursor: pointer;
    }
  }

  @keyframes shadowFrame {
    0% {
      text-shadow: 0 0 0 red;
    }
    50% {
      text-shadow: 0 0 10px red;
    }
    100% {
      text-shadow: 0 0 0 red;
    }
  }
}

.table-economicos,
.table-gestion{
  tbody th {
    background: $primary1;
    color: $white;
  }
}

.bg-primary-1 {
  background: $primary1 !important;
}
.bg-primary-2 {
  background: $primary2 !important;
}
.bg-primary-3 {
  background: $primary3 !important;
}
.bg-primary-4 {
  background: $primary4 !important;
}
.bg-secondary-1 {
  background: $secondary1 !important;
}
.bg-secondary-2 {
  background: $secondary2 !important;
}
.bg-secondary-3 {
  background: $secondary3 !important;
}
.bg-secondary-4 {
  background: $secondary4 !important;
}
.bg-secondary-5 {
  background: $secondary5 !important;
}
.bg-secondary-6 {
  background: $secondary6 !important;
}

.btn-ver-ficha {
  background: $primary3;
  color: #fff;
  line-height: 2em;
  display: block;
  border-radius: 2px;
  font-weight: bold;
  &:hover {
    text-decoration: none;
    color: #fff;
    background: darken($primary3, 10%);
  }
}

.btn-primary-link {
  color: $secondary6;
  font-weight: bold;
  &:hover {
    text-decoration: none;
    color: $secondary5;
  }
}
